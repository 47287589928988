import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ITableColumnConfig, TABLE_CONFIGURATIONS } from '../shared/components/common-table/table-configurations';
import { LoadNewTablePreferences, MoveColumn, ToggleTableColumn } from './common-table.actions';

export interface IColumn {
  name: string;
  key: string;
  type: 'text' | 'number' | 'date' | 'custom';
  stickyLeft: boolean;
  stickyRight: boolean;
  enabled: boolean;
}

export interface ITableConfig {
  renderColumns: ITableColumnConfig[];
  displayColumns: string[];
}

export interface TableStateModel {
  [key: string]: ITableConfig;
}

@State<TableStateModel>({
  name: 'tablePreferences',
  defaults: {},
})
@Injectable()
export class TablePreferenceState {
  static table(tableKey: string) {
    return createSelector([TablePreferenceState], (state: TableStateModel) => {
      return state[tableKey];
    });
  }

  @Action(LoadNewTablePreferences)
  public loadNewTablePreferences(ctx: StateContext<TableStateModel>, action: LoadNewTablePreferences) {
    const stateCopy = Object.assign({}, ctx.getState());
    const tableKey = action.tableKey;

    let tableState = stateCopy[tableKey] || null;

    const columns = tableState
      ? this.resolveNewColumnsForTableKey(tableKey, tableState.renderColumns)
      : this.resolveNewColumnsForTableKey(tableKey, []);

    if (tableState == null) tableState = {} as ITableConfig;
    tableState.renderColumns = columns;
    tableState.displayColumns = this.resolveDisplayColumns(columns);

    stateCopy[tableKey] = tableState;
    ctx.setState(stateCopy);
  }

  @Action(ToggleTableColumn)
  public toggleTableColumn(ctx: StateContext<TableStateModel>, action: ToggleTableColumn) {
    const tableKey = action.tableKey;
    const columnKey = action.columnKey;

    let tableState = Object.assign({}, ctx.getState()[tableKey]);
    if (ctx.getState()[tableKey]) {
      tableState = this.toggleColumnForTable(tableState, columnKey);
    }

    const stateCopy = Object.assign({}, ctx.getState());
    stateCopy[tableKey] = tableState;

    ctx.setState(stateCopy);
  }

  @Action(MoveColumn)
  public moveTableColumn(ctx: StateContext<TableStateModel>, action: MoveColumn) {
    const tableKey = action.tableKey;

    let tableState = Object.assign({}, ctx.getState()[tableKey]);
    tableState = this.moveColumnInTable(tableState, action.cdkDragDropEvent);

    const stateCopy = Object.assign({}, ctx.getState());
    stateCopy[tableKey] = tableState;

    ctx.setState(stateCopy);
  }

  private toggleColumnForTable(tableState: any, columnKey: string): any {
    const columnIndex = tableState.renderColumns.findIndex(c => c.key === columnKey);
    if (columnIndex > -1) {
      const column = tableState.renderColumns[columnIndex];
      column.enabled = !column.enabled;
    }

    return this.setColumnsForTable(tableState);
  }

  private moveColumnInTable(tableState: any, cdkDragDropEvent: CdkDragDrop<any[]>) {
    const renderColumnsCopy = tableState.renderColumns.slice(0);
    const topColumnsCounts = renderColumnsCopy.filter(c => c.onTop).length;
    const bottomColumnsCounts = renderColumnsCopy.filter(c => c.onBottom).length;
    // Keep the top column at the top
    if (cdkDragDropEvent.currentIndex < topColumnsCounts) {
      cdkDragDropEvent.currentIndex = topColumnsCounts;
    }

    // Keep the bottom column at the bottom
    if (cdkDragDropEvent.currentIndex > renderColumnsCopy.length - 1 - bottomColumnsCounts) {
      cdkDragDropEvent.currentIndex = renderColumnsCopy.length - 1 - bottomColumnsCounts;
    }

    moveItemInArray(renderColumnsCopy, cdkDragDropEvent.previousIndex, cdkDragDropEvent.currentIndex);
    tableState.renderColumns = renderColumnsCopy;

    return this.setColumnsForTable(tableState);
  }

  private setColumnsForTable(tableState: any): any {
    tableState.renderColumns = tableState.renderColumns.slice(0);
    tableState.displayColumns = this.resolveDisplayColumns(tableState.renderColumns.slice(0));
    return tableState;
  }

  private resolveDisplayColumns(columns: IColumn[]): string[] {
    return columns.filter(c => c.enabled).map(c => c.key);
  }

  private resolveNewColumnsForTableKey(tableKey: string, savedColumns: any[] = null): any[] {
    const columns = TABLE_CONFIGURATIONS[tableKey].columns.slice(0);
    let tableColumns = columns;

    if (savedColumns) {
      // Clean up saved columns list to avoid tables breaking due to having columns that no longer
      // exist being loaded from the saved preferences and load the columns in the saved order.
      for (let i = savedColumns.length - 1; i >= 0; --i) {
        const foundIndex = columns.findIndex(c => c.key === savedColumns[i].key);
        foundIndex > -1 ? savedColumns.splice(i, 1, columns[foundIndex]) : savedColumns.splice(i, 1);
      }

      tableColumns = savedColumns;
      columns.forEach(col => {
        // Ensures that any new columns added to the COLUMN_MAP get merged with the already saved preferences.
        const found = savedColumns.findIndex(c => c.key === col.key) > -1;
        if (!found) tableColumns.push(col);
      });
    }

    // Always set the actions column as the last column in the table.
    const actionsColumnIndex = tableColumns.findIndex(c => c.key === 'actions');
    if (actionsColumnIndex > -1) {
      const actionsColumn = Object.assign({}, tableColumns[actionsColumnIndex]);
      tableColumns.splice(actionsColumnIndex, 1);
      tableColumns.push(actionsColumn);
    }

    // Determine whether a column is displayed by the tablePreferences in localstorage
    if (localStorage.getItem('tablePreferences')) {
      const localStorageColumns: ITableConfig = JSON.parse(localStorage.getItem('tablePreferences'))[tableKey];
      if (localStorageColumns) {
        localStorageColumns.renderColumns.forEach(c => {
          const index = tableColumns.findIndex(t => t.key === c.key);
          if (index >= 0) {
            tableColumns[index].enabled = c.enabled;
          }
        });
      }
    }

    return tableColumns;
  }
}

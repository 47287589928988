<div id="app-layout">
  <div
    id="app-sidebar"
    [ngClass]="{ 'sidenav-closed': !sidebarExpanded, 'sidenav-open': sidebarExpanded }"
    (mouseover)="changeNavOpen(true)"
    (mouseleave)="changeNavOpen(false)"
  >
    <div id="logo-container" class="row justify-content-center">
      <img id="logo" src="/assets/images/logo-un.png" />
    </div>
    <div style="margin-top: 30px" id="nav-area">
      <ng-container *ngFor="let item of userNavigationLinks">
        <ng-container *ngIf="item.enabled">
          <app-menu-item
            [navOpened]="sidebarExpanded"
            [currentParentMenu]="currentParentMenu"
            [showChildrenMenu]="showChildrenMenu"
            [currentChildMenu]="currentChildMenu"
            [menuItem]="item"
            (openChildrenMenu)="openChildrenMenu($event)"
            (changeParentMenu)="changeParentMenu($event)"
            (changeCurrentChildMenu)="changeCurrentChildMenu($event)"
          ></app-menu-item>
        </ng-container>
      </ng-container>
    </div>

    <div class="menu-footer">
      <ng-container *ngFor="let item of webServiceRoutes">
        <ng-container *ngIf="item.enabled">
          <app-menu-item
            [navOpened]="sidebarExpanded"
            [currentParentMenu]="currentParentMenu"
            [showChildrenMenu]="showChildrenMenu"
            [currentChildMenu]="currentChildMenu"
            [menuItem]="item"
            (openChildrenMenu)="openChildrenMenu($event)"
            (changeParentMenu)="changeParentMenu($event)"
            (changeCurrentChildMenu)="changeCurrentChildMenu($event)"
            [alwaysShowChildrenMenu]="true"
          ></app-menu-item>
        </ng-container>
      </ng-container>
      <div class="sidenav-line"></div>
    </div>
  </div>

  <div id="app-content" [class.expanded]="sidebarExpanded">
    <div id="app-menu">
      <div id="upper-menu">
        <div class="col-3 p-0 search-content"></div>
        <div class="col-9 p-0 upper-right">
          <div id="userArea">
            <span id="userButton" [matMenuTriggerFor]="menu" #userMenuTrigger="matMenuTrigger">
              <div class="row">
                <div class="userCircle">{{ (userService.user$ | async)?.initials }}</div>
                <div class="pl-2" style="line-height: 60px">
                  {{ (userService.user$ | async)?.firstName }} {{ (userService.user$ | async)?.lastName }}
                </div>
                <div class="my-auto">
                  <div class="icon-wrap" [ngClass]="{ flip: userMenuTrigger.menuOpen }">
                    <mat-icon class="navbar-arrow" svgIcon="down-arrow"></mat-icon>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <mat-menu #menu="matMenu" backdropClass="userMenu">
            <div class="right-menu">
              <div class="first-part">
                <div class="current-org">
                  <button id="userOrg" class="text-overflow" mat-button disabled>
                    {{ userService.currentOrg.cfOrgName }}
                  </button>
                </div>
                <mat-divider></mat-divider>
              </div>
              <div class="second-part">
                <ng-container *ngIf="userService.orgList as orgs">
                  <ng-container *ngIf="orgs.length > 1">
                    <div class="search">
                      <mat-form-field fxFlex="1 1 auto" (click)="$event.stopPropagation()">
                        <mat-label>Search</mat-label>
                        <input
                          matInput
                          [ngModel]="searchQuery"
                          (ngModelChange)="searchQuery$.next($event)"
                          (change)="$event.stopPropagation()"
                        />
                      </mat-form-field>
                    </div>
                    <button
                      class="mat-menu-item"
                      *ngFor="let org of filteredOrgs | async"
                      (click)="userService.updateOrganization(org)"
                      tabindex="-1"
                    >
                      {{ org.cfOrgName }}
                    </button>
                  </ng-container>
                </ng-container>
              </div>
              <div class="third-part">
                <mat-divider></mat-divider>
                <button class="mat-menu-item" (click)="authService.logout()" tabindex="-1">
                  <mat-icon>logout</mat-icon>
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </mat-menu>
          <!-- <ul class="user-panel-options">
              <li *ngIf="userService.currentOrg" class="text-overflow">
                <button id="userOrg" class="text-overflow" mat-button disabled>
                  {{ userService.currentOrg.cfOrgName }}
                </button>
              </li>
              <li>
                <button (click)="authService.logout()" mat-menu-item>LOGOUT</button>
              </li>
            </ul>
          </mat-menu> -->
        </div>
      </div>
    </div>
    <div id="content">
      <div id="main-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

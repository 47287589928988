<div fxLayout="row" *ngIf="chart$ | async as loadedChart" fxLayoutGap="10px" fxLayoutAlign="end">
	<div 
		fxFlex="0 1 auto"
		fxLayout="row"
		fxLayoutAlign="center center"
		class="legend-item"
		*ngFor="let item of items$ | async; let i = index"
		[style.borderColor]="item.strokeStyle"
		[style.borderStyle]="item.lineDash && item.lineDash.length ? 'dashed' : 'solid'"

	>
		<span class="legend-box" [style.background]="item.lineDash && item.lineDash.length ? 'repeating-linear-gradient(45deg, ' + item.strokeStyle + ', ' + item.strokeStyle + ' 4px, transparent 4px, transparent 5px)' : item.strokeStyle"></span>
		<span class="legend-label">{{item.text}}</span>
	</div>
</div>
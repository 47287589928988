import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@models/menu.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('100ms ease-out')),
      transition('default => rotated', animate('200ms ease-in')),
    ]),
    trigger('slideUpDown', [
      transition('void => *', [
        style({ height: 0, margin: 0, padding: 0, opacity: 0 }),
        animate(200, style({ height: '*', margin: '*', padding: '*', opacity: 1 })),
      ]),
      transition('* => void', [
        style({ height: '*', margin: '*', padding: '*', opacity: 1 }),
        animate(100, style({ height: 0, margin: 0, padding: 0, opacity: 0 })),
      ]),
    ]),
    trigger('textDisplay', [
      transition('void => *', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate(100, style({ opacity: 0 }))]),
    ]),
  ],
})
export class MenuItemComponent implements OnInit, OnChanges {
  @Input() public menuItem: MenuItem;
  @Input() public navOpened: boolean;
  @Input() public alwaysShowChildrenMenu = false;
  @Input() public currentChildMenu: string = '';
  @Input() public showChildrenMenu: string = ''; // show children menu
  @Input() public currentParentMenu: string = ''; // display background
  @Output() public changeCurrentChildMenu = new EventEmitter<string>();
  @Output() public openChildrenMenu = new EventEmitter<string>(); // just open children menu list
  @Output() public changeParentMenu = new EventEmitter<string>();

  get getShowChildrenMenu() {
    return this.showChildrenMenu;
  }

  constructor(private router: Router) {}

  state: string = 'default';

  ngOnInit(): void {}

  public showSubChildrenMenu() {
    if (this.showChildrenMenu !== this.menuItem.labelName) {
      if (!this.menuItem.childrenMenu && this.menuItem.routeAddress) {
        this.router.navigate(['/' + this.menuItem.routeAddress]);
        this.changeCurrentChildMenu.emit('');
        this.openChildrenMenu.emit('');
        this.changeParentMenu.emit(this.menuItem.labelName);
      } else {
        this.openChildrenMenu.emit(this.menuItem.labelName);
      }
    } else {
      this.openChildrenMenu.emit('');
    }
  }

  public setCurrentChildMenu(name, externalLink: boolean) {
    if (!externalLink) {
      this.changeCurrentChildMenu.emit(name);
      this.changeParentMenu.emit(this.menuItem.labelName);
    }
  }

  public openExternalLink(link: string): void {
    window.open(link, '_blank');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showChildrenMenu && !changes.showChildrenMenu.firstChange) {
      if (changes.showChildrenMenu.currentValue === this.menuItem.labelName) {
        this.state = 'rotated';
      } else {
        this.state = 'default';
      }
    }
  }
}

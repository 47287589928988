<h2 mat-dialog-title class="title">{{ title }}</h2>
<mat-dialog-content>
  <div class="content">
    <div *ngFor="let item of dataList" class="content-item">
      <a
        href="javascript:void(0)"
        (click)="onExternalHyperlinkClick($event, dataConfig.url, item[dataConfig.idKey])"
        *ngIf="dataConfig.url"
        [innerHTML]="dataConfig.textKey ? item[dataConfig.textKey] : item"
      ></a>
      <div *ngIf="!dataConfig.url" [innerHTML]="dataConfig.textKey ? item[dataConfig.textKey] : item"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-bottom: 0; padding-bottom: 0">
  <button mat-raised-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>

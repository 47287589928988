import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ChartComponent } from './components/chart/chart.component';
import { PrettyLegendComponent } from './components/chart/legends/pretty-legend/pretty-legend.component';
import { TableLegendComponent } from './components/chart/legends/table-legend/table-legend.component';
import { TableColumnCustomizerComponent } from './components/column-customizer/table-column-customizer.component';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonTablePaginator } from './components/common-table/common-table-paginator.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { CustomCellsWrapperComponent } from './components/common-table/custom-cells-wrapper.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { RefsListDialogComponent } from './components/refs-list-dialog/refs-list-dialog.component';
import { ScrollObserverDirective } from './directives/scroll-observer.directive';
import { TransformObserverDirective } from './directives/transform-observer.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TableCellPipe } from './pipes/table-cell.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatMenuModule,
    DragDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatBadgeModule,
    RouterModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgxPaginationModule,
    NzPaginationModule,
    UiSwitchModule.forRoot({
      switchColor: '#ffffff',
      defaultBoColor: '#ffffff',
      checkedLabel: 'Priority On',
      uncheckedLabel: 'Priority Off',
      uncheckedTextColor: '#000000',
      checkedTextColor: '#ffffff',
    }),
  ],
  declarations: [
    PageHeaderComponent,
    LoadingIndicatorComponent,
    MenuItemComponent,
    SafeUrlPipe,
    ChartComponent,
    TableLegendComponent,
    PrettyLegendComponent,
    CommonDialogComponent,
    CommonTableComponent,
    CommonTablePaginator,
    CustomCellsWrapperComponent,
    RefsListDialogComponent,
    TransformObserverDirective,
    ScrollObserverDirective,
    TableCellPipe,
    TableColumnCustomizerComponent,
  ],
  exports: [
    PageHeaderComponent,
    LoadingIndicatorComponent,
    DatePipe,
    MenuItemComponent,
    MatPaginatorModule,
    SafeUrlPipe,
    ChartComponent,
    TableLegendComponent,
    PrettyLegendComponent,
    CommonDialogComponent,
    CommonTablePaginator,
    CommonTableComponent,
    CustomCellsWrapperComponent,
    RefsListDialogComponent,
    TransformObserverDirective,
    ScrollObserverDirective,
    TableCellPipe,
    TableColumnCustomizerComponent,
  ],
  providers: [DatePipe, TableCellPipe],
})
export class SharedModule {}

<ng-content select="[customCells]"></ng-content>

<div class="table-header-toolbar" #tableHeaderToolbar>
  <ng-content select="[tableToolbar]"></ng-content>
</div>

<div
  [ngClass]="{ 'table-container': true, 'table-rounded': !nested, 'table-header-top': headerOnTop }"
  appTransformObserver
  #appTransformObserver="appTransformObserver"
  appScrollObserver
  #appScrollObserver="appScrollObserver"
  [ngStyle]="{ height: containerHeight ? containerHeight - 100 + 'px' : !nested && tableHeight ? tableHeight : 'auto' }"
>
  <table
    mat-table
    matSort
    [style.width.px]="appTransformObserver.observedSizes.width"
    [dataSource]="matTableDataSource"
    multiTemplateDataRows
    class="om-table mat-elevation-z8"
    (matSortChange)="sortChange($event)"
    [ngClass]="{ 'white-background': whiteBackground, 'child-table': childTable, equipmentTable: equipmentTable }"
  >
    <!-- DEFAULT CELL TEMPLATES -->
    <ng-container *ngFor="let column of tableConfig.renderColumns">
      <ng-container *ngIf="column.type !== 'custom'">
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'arrow'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element"></th>
            <td mat-cell *matCellDef="let element">
              <div class="arrow" [@rotatedState]="element.isExpanded ? 'rotated' : 'default'">
                <mat-icon>expand_more</mat-icon>
              </div>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'hyperlink'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <a href="javascript:void(0)" (click)="onHyperLinkClick($event, element, column.hyperLinkConfig)">
                  {{ element[column.key] }}
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'externalHyperlink'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <a
                  href="javascript:void(0)"
                  (click)="onExternalHyperlinkClick($event, element, column.hyperLinkConfig)"
                >
                  {{ element[column.key] }}
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'colorText'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <div [className]="element[column.key + 'Color']">
                  {{ element[column.key] }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'dateStr'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                {{ element[column.key] ? element[column.key].split('T')[0] : '' }}
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchCase="'bool'" [matColumnDef]="column.key">
            <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
              {{ column.name }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                <mat-checkbox [disabled]="true" color="primary" [checked]="element[column.key]"></mat-checkbox>
              </div>
            </td>
          </ng-container>

          <ng-container *ngSwitchDefault [matColumnDef]="column.key">
            <ng-container *ngIf="unSortableColumns.includes(column.key)">
              <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element">
                {{ column.name }}
              </th>
            </ng-container>
            <ng-container *ngIf="!unSortableColumns.includes(column.key)">
              <th mat-header-cell class="om-table-header" *matHeaderCellDef="let element" mat-sort-header>
                {{ column.name }}
              </th>
            </ng-container>

            <td mat-cell *matCellDef="let element">
              <div class="td-div">
                {{ element | tableCell : column }}
              </div>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container [ngSwitch]="allowRowExpansion">
      <ng-container *ngSwitchCase="false">
        <tr mat-header-row *matHeaderRowDef="tableConfig.displayColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: tableConfig.displayColumns"
          (click)="rowSelected.emit(element)"
        ></tr>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <tr mat-header-row *matHeaderRowDef="tableConfig.displayColumns"></tr>
        <!-- EXPANDED CELL TEMPLATE -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="tableConfig.displayColumns.length"
            class="expanded-cell"
          >
            <div class="example-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
              <div
                class="nested-table-container"
                [style.width.px]="appTransformObserver.observedSizes.width"
                [style.left.px]="appScrollObserver.scrollLeft"
              >
                <ng-container
                  [ngTemplateOutlet]="templateRef"
                  [ngTemplateOutletContext]="{ element: element }"
                ></ng-container>
              </div>
            </div>
          </td>
        </ng-container>
        <tr
          mat-row
          *matRowDef="let element; columns: tableConfig.displayColumns"
          [class.example-expanded-row]="element.isExpanded"
          [class.selectable]="true"
          (click)="toggleRow(element)"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </ng-container>
    </ng-container>

    <!-- NO DATA ROW -->
    <ng-container *matNoDataRow>
      <tr class="mat-row" *ngIf="!tableLoading">
        <td class="mat-cell" colspan="9999">
          <div class="td-div">No data available to display.</div>
        </td>
      </tr>
    </ng-container>
  </table>

  <app-loading-indicator *ngIf="tableLoading"></app-loading-indicator>
</div>
<app-common-table-paginator
  [dataLength]="dataLength"
  [hidePaginator]="hidePaginator"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (pageChanged)="pageChanged.emit($event)"
></app-common-table-paginator>

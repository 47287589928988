import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

export interface IRefsListData {
  title: string;
  list: any;
  config: IRefsListConfig;
  bottomDistance?: number;
  rightDistance?: number;
  btnY?: number;
  btnX?: number;
}

export interface IRefsListConfig {
  url: string;
  idKey: string;
  textKey: string;
  highlightText?: string;
}

@Component({
  selector: 'app-refs-list-dialog',
  templateUrl: './refs-list-dialog.component.html',
  styleUrls: ['./refs-list-dialog.component.scss'],
})
export class RefsListDialogComponent implements OnInit {
  public title: string;
  public dataList: any;
  public dataConfig: IRefsListConfig;
  public highlightText: string;
  constructor(
    public dialogRef: MatDialogRef<RefsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRefsListData,
    private dialogElementRef: ElementRef<HTMLElement>
  ) {
    this.title = data.title;
    this.dataList = data.list;
    this.dataConfig = data.config;
    this.highlightText = data.config.highlightText ? data.config.highlightText : '';
  }

  public ngOnInit(): void {
    if (
      this.data.btnX !== null && this.data.btnX !== undefined &&
      this.data.btnY !== null && this.data.btnY !== undefined
    ) {
      const matDialogConfig: MatDialogConfig = new MatDialogConfig();
      let offsetTop = 0;
      if (this.dialogElementRef.nativeElement.offsetHeight > this.data.bottomDistance) {
        offsetTop = this.dialogElementRef.nativeElement.offsetHeight - this.data.bottomDistance;
      }
      let offsetLeft = 0;
      if (this.dialogElementRef.nativeElement.offsetWidth > this.data.rightDistance) {
        offsetLeft = this.dialogElementRef.nativeElement.offsetWidth - this.data.rightDistance;
      }
      matDialogConfig.position = { left: `${this.data.btnX - offsetLeft - 10}px`, top: `${this.data.btnY - offsetTop - 70}px` };
      this.dialogRef.updatePosition(matDialogConfig.position);
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public onExternalHyperlinkClick(event: any, url: any, primaryKeyAccessor: any): void {
    event.stopImmediatePropagation();
    window.open(url(primaryKeyAccessor), '_blank');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@models/organization.model';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SsoUser, User } from '../models/user.model';
import { OrganizationService } from './../../../core/services/organization.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentSsoUser = new SsoUser();

  private _user = new BehaviorSubject<User>(new User());
  public user$ = this._user.asObservable();
  public userCountryCode: string = null;

  public get userValue(): User {
    return this._user.value;
  }

  private _currentOrg: Organization;
  get currentOrg(): Organization {
    return this._currentOrg;
  }

  private _selectedOrg = new ReplaySubject<Organization>();
  public selectedOrg$ = this._selectedOrg.asObservable();
  public orgSubscribe;

  public orgList: Organization[] = [];
  private _orgList = new ReplaySubject<Organization[]>();
  public orgList$ = this._orgList.asObservable();

  public currentTimeZone: string = null;

  constructor(private http: HttpClient, private toastr: ToastrService, private orgService: OrganizationService) {}

  public get getCurrentSsoUser(): SsoUser {
    return this.currentSsoUser;
  }

  public initializeUser(claims: any): Promise<boolean> {
    this.currentSsoUser = new SsoUser(claims.sub, claims.name, claims.preferred_username, claims.preferred_username);
    return new Promise<boolean>((resolveFn, rejectFn) => {
      this.getCurrentUserInfo().subscribe(
        res => {
          const user = new User(res);
          user.initials = this.extractUserInitials(user);
          user.org = user.org.toString();
          this._user.next(user);

          const viewAllOrgs = user.siteAdministrator || user.clearFreightUsers;
          this.orgService.getOrganizations().subscribe(
            orgs => {
              const orgIndex = orgs.findIndex(o => o.cfOrgId == user.org);
              if (orgIndex > -1) {
                this.setCurrentOrg(orgs[orgIndex]);
              } else {
                this.setCurrentOrg(orgs[0]);
              }
              this.orgList = orgs;
              this._orgList.next(orgs);
              resolveFn(true);
            },
            error => {
              rejectFn(false);
            }
          );
          // resolveFn(true);
        },
        error => {
          rejectFn(false);
        }
      );
    });
  }

  public refreshUser() {
    this.getCurrentUserInfo().subscribe(res => {
      const user = new User(res);
      user.initials = this.extractUserInitials(user);
      user.org = user.org.toString();
      this._user.next(user);
    });
  }

  public setCurrentOrg(org: Organization) {
    this._currentOrg = org;
    this._selectedOrg.next(org);
  }

  public getCurrentUserInfo(): Observable<User> {
    return this.http.get<User>(`${environment.clearViewApi}user/info`);
  }

  public updateOrganization(org: Organization) {
    this.http.get<void>(`${environment.clearViewApi}user/updateOrg/${org.cfOrgId}`).subscribe(_ => {
      const user = this.userValue;
      user.org = org.cfOrgId;
      user.orgName = org.cfOrgName;
      this._user.next(user);
      this.setCurrentOrg(org);
    });
  }
  private extractUserInitials(user: User): string {
    let initials = '';
    if (user.firstName.length) initials = initials + user.firstName[0];
    if (user.lastName.length) initials = initials + user.lastName[0];
    return initials.toUpperCase();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: `
    <div class="row center" [ngStyle]="{ 'margin-top': marginTop }">
      <mat-spinner></mat-spinner>
    </div>
  `,
  styles: [
    `
      .center {
        justify-content: center;
        margin-bottom: 15vh;
      }

      ::ng-deep .mat-progress-spinner circle,
      .mat-spinner circle {
        stroke: #0077e7;
      }
    `,
  ],
})
export class LoadingIndicatorComponent {
  @Input() marginTop = '15vh';
}

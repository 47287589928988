export interface IHyperlinkColumnConfig {
  urlGrabber(primaryKey: number | string): string;
  primaryKeyAccessor: string;
}

export interface IRelatedObjConfig {
  idKey: string;
  textKey: string;
  elementKey: string;
  background?: string;
  width?: string;
}

export interface ITableConfig {
  renderColumns: ITableColumnConfig[];
  displayColumns: string[];
}

export type ColumnType =
  | 'text'
  | 'date'
  | 'number'
  | 'numberWithUnit'
  | 'location'
  | 'orgName'
  | 'hyperlink'
  | 'externalHyperlink'
  | 'bookingStatus'
  | 'poStatus'
  | 'custom'
  | 'bool'
  | 'actionBtn'
  | 'colorText'
  | 'relatedObj'
  | 'dateStr'
  | 'amount'
  | 'arrow';

export interface ITableColumnConfig {
  name: string;
  key: string;
  unitKey?: string;
  suffix?: string;
  type: ColumnType;
  enabled: boolean;
  hyperLinkConfig?: IHyperlinkColumnConfig;
  relatedObjConfig?: IRelatedObjConfig;
  sortKey?: string;
  alwaysShow?: boolean;
  onTop?: boolean;
  onBottom?: boolean;
}

export interface ITableConfiguration {
  columns: ITableColumnConfig[];
}

export const TABLE_CONFIGURATIONS: { [key: string]: ITableConfiguration } = {
  'container-forecast-detail-table': {
    columns: [
      { name: 'Container #', key: 'ContainerNumber', type: 'text', enabled: true, sortKey: 'ContainerNumber' },
      { name: 'Shipment', key: 'Shipment', type: 'text', enabled: true, sortKey: 'Shipment' },
      { name: 'Type', key: 'Type', type: 'text', enabled: true, sortKey: 'Type' },
      { name: 'Load Port Name', key: 'LoadPortName', type: 'text', enabled: true, sortKey: 'LoadPortName' },
      { name: 'Load Port Code', key: 'LoadPortCode', type: 'text', enabled: true, sortKey: 'LoadPortCode' },
      {
        name: 'Discharge Port Name',
        key: 'DischargePortName',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortName',
      },
      {
        name: 'Discharge Port Code',
        key: 'DischargePortCode',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortCode',
      },
      { name: 'ETD', key: 'ETD', type: 'dateStr', enabled: true, sortKey: 'ETD' },
      { name: 'ATD', key: 'ATD', type: 'dateStr', enabled: true, sortKey: 'ATD' },
      { name: 'ETA', key: 'ETA', type: 'dateStr', enabled: true, sortKey: 'ETA' },
      { name: 'ATA', key: 'ATA', type: 'dateStr', enabled: true, sortKey: 'ATA' },
      { name: 'Order Refs', key: 'OrderRefs', type: 'custom', enabled: true, sortKey: 'OrderRefs' },
    ],
  },

  'transit-time-detail-table': {
    columns: [
      { name: 'Container #', key: 'ContainerNumber', type: 'text', enabled: true, sortKey: 'ContainerNumber' },
      { name: 'Shipment', key: 'Shipment', type: 'text', enabled: true, sortKey: 'Shipment' },
      { name: 'Load Port Name', key: 'LoadPortName', type: 'text', enabled: true, sortKey: 'LoadPortName' },
      { name: 'Load Port Code', key: 'LoadPortCode', type: 'text', enabled: true, sortKey: 'LoadPortCode' },
      {
        name: 'Discharge Port Name',
        key: 'DischargePortName',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortName',
      },
      {
        name: 'Discharge Port Code',
        key: 'DischargePortCode',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortCode',
      },
      { name: 'ATD', key: 'ATD', type: 'dateStr', enabled: true, sortKey: 'ATD' },
      { name: 'ATA', key: 'ATA', type: 'dateStr', enabled: true, sortKey: 'ATA' },
      { name: 'Transit Time(Days)', key: 'TransitTime', type: 'text', enabled: true, sortKey: 'TransitTime' },
    ],
  },

  'equipment-utilization-detail-table': {
    columns: [
      { name: 'Container #', key: 'ContainerNumber', type: 'text', enabled: true, sortKey: 'ContainerNumber' },
      { name: 'Shipment', key: 'Shipment', type: 'text', enabled: true, sortKey: 'Shipment' },
      { name: 'Supplier', key: 'Supplier', type: 'text', enabled: true, sortKey: 'Supplier' },
      { name: 'Load Port Name', key: 'LoadPortName', type: 'text', enabled: true, sortKey: 'LoadPortName' },
      { name: 'Load Port Code', key: 'LoadPortCode', type: 'text', enabled: true, sortKey: 'LoadPortCode' },
      {
        name: 'Discharge Port Name',
        key: 'DischargePortName',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortName',
      },
      {
        name: 'Discharge Port Code',
        key: 'DischargePortCode',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortCode',
      },
      { name: 'Mode', key: 'Mode', type: 'text', enabled: true, sortKey: 'Mode' },
      { name: 'Type', key: 'Type', type: 'text', enabled: true, sortKey: 'Type' },
      { name: 'Order Refs', key: 'OrderRefs', type: 'custom', enabled: true, sortKey: 'OrderRefs' },
      {
        name: 'Weight',
        key: 'Weight',
        unitKey: 'WeightUnit',
        type: 'numberWithUnit',
        enabled: true,
        sortKey: 'Weight',
      },
      {
        name: 'Volume',
        key: 'Volume',
        unitKey: 'VolumeUnit',
        type: 'numberWithUnit',
        enabled: true,
        sortKey: 'Volume',
      },
    ],
  },

  'costing-shipment-table': {
    columns: [
      { name: 'Shipment #', key: 'ShipmentNumber', type: 'text', enabled: true, sortKey: 'ShipmentNumber' },
      { name: 'Charge Type', key: 'ChargeType', type: 'text', enabled: true, sortKey: 'ChargeType' },
      { name: 'Load Port Name', key: 'LoadPortName', type: 'text', enabled: true, sortKey: 'LoadPortName' },
      { name: 'Load Port Code', key: 'LoadPortCode', type: 'text', enabled: true, sortKey: 'LoadPortCode' },
      {
        name: 'Discharge Port Name',
        key: 'DischargePortName',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortName',
      },
      {
        name: 'Discharge Port Code',
        key: 'DischargePortCode',
        type: 'text',
        enabled: true,
        sortKey: 'DischargePortCode',
      },
      { name: 'ETD', key: 'ETD', type: 'dateStr', enabled: true, sortKey: 'ETD' },
      { name: 'ATD', key: 'ATD', type: 'dateStr', enabled: true, sortKey: 'ATD' },
      { name: 'ETA', key: 'ETA', type: 'dateStr', enabled: true, sortKey: 'ETA' },
      { name: 'ATA', key: 'ATA', type: 'dateStr', enabled: true, sortKey: 'ATA' },
      { name: 'Total Amount', key: 'totalAmount', type: 'amount', enabled: true, sortKey: 'totalAmount' },
      { name: 'HMF', key: 'HMF', type: 'text', enabled: true, sortKey: 'HMF' },
      { name: 'MPF', key: 'MPF', type: 'text', enabled: true, sortKey: 'MPF' },
      { name: 'Order Refs', key: 'OrderRefs', type: 'custom', enabled: true, sortKey: 'OrderRefs' },
    ],
  },
};

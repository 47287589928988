import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseLegend } from '../abstract-legend';

@Component({
  selector: 'app-table-legend',
  templateUrl: './table-legend.component.html',
  styleUrls: ['./table-legend.component.scss'],
})
export class TableLegendComponent extends BaseLegend implements OnInit, OnDestroy, OnChanges {
  public currentChart: any = null;
  public currentLabels: any[] = [];
  private chartSub: Subscription = new Subscription();
  public get otherDataset(): { data: any[] } | void {
    return this.chart?.data.datasets.find((d: any) => d.label === 'Other');
  }

  @Input()
  dataFormatter?: (value: any, datasetLabel: string, category: string, currency: string) => string | number;

  @Input()
  categoryName: string | null = null;

  @Input()
  currentCurrency: string = '';

  ngOnInit(): void {
    this.chartSub = this.chart$.subscribe(res => {
      this.currentChart = res;
      this.currentLabels = res?.options.plugins.legend.labels.generateLabels(res);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoryName && !changes.categoryName.firstChange && this.chart$.value) {
      this.currentChart = this.chart$.value;
      this.currentLabels = this.chart$.value.options.plugins.legend.labels.generateLabels(this.chart$.value);
    }
  }

  ngOnDestroy(): void {
    this.chartSub.unsubscribe();
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dateFormat } from '../../../shared/utils/date-format';
import { UserService } from '../services/user.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let orgId = '';
    // let orgType = '';
    // if (this.userService.currentOrg) {
    //   orgId = this.userService.currentOrg.id.toString();
    //   orgType = this.userService.currentOrg.type.toString();
    // }

    req = dateFormat(req);

    let headersClone = req.headers;
    let currentTZ = this.userService.currentTimeZone;
    headersClone = headersClone.set('Time-Zone', currentTZ);

    // if (networkId) {
    //     if (orgId) {
    //         headersClone = headersClone.set("OMS-Org-Id", orgId)
    //     }
    //     if (orgType) {
    //         headersClone = headersClone.set("OMS-Org-Type", orgType)
    //     }
    //     const cloneReq = req.clone({
    //         headers: headersClone.set("OMS-Network-Id", networkId)
    //     });
    //     return next.handle(cloneReq)
    // } else {
    //   const cloneReq = req.clone({
    //     headers: headersClone
    //   });
    //   return next.handle(cloneReq)
    // }
    const cloneReq = req.clone({
      headers: headersClone,
    });
    return next.handle(cloneReq);
  }
}

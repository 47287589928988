import { Injectable } from '@angular/core';
import { Palette } from 'src/app/core/models/palette.model';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ColorService {
  public userPalette: Palette = null;

  constructor(private userService: UserService) {
    this.prepApplication();
  }

  private async prepApplication(): Promise<void> {
    this.userPalette = new Palette('');
  }
}

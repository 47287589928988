export class SsoUser {
  id: string;
  name: string;
  email: string;
  username: string;

  constructor(id?: string, name?: string, email?: string, username?: string) {
    this.id = id ? id : '';
    this.name = name ? name : '';
    this.email = email ? email : '';
    this.username = username ? username : '';
  }
}

export class User {
  private _phone: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username?: string;
  initials?: string;
  public org: string;
  public orgName: string;
  public siteAdministrator: boolean;
  public clearFreightUsers: boolean;

  public get phone(): string {
    return this.convertTel(this._phone);
  }
  public set phone(val: string) {
    this._phone = val;
  }

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  private convertTel(tel: string): string {
    if (!tel) {
      return '';
    }
    const value = tel.toString().trim().replace(/\D/g, '');
    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let countryCode = '+';
    let cityCode;
    let telNumber;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        countryCode += '1';
        cityCode = value.slice(0, 3);
        telNumber = value.slice(3);
        break;
      case 11: // +CPPP####### -> CCC (PP) ###-####
        countryCode += value[0];
        cityCode = value.slice(1, 4);
        telNumber = value.slice(4);
        break;
      case 12: // +CCCPP####### -> CCC (PP) ###-####
        countryCode += value.slice(0, 3);
        cityCode = value.slice(3, 5);
        telNumber = value.slice(5);
        break;
      default:
        return tel;
    }
    if (countryCode === '+1') {
      countryCode = '';
    }
    telNumber = telNumber.slice(0, 3) + '-' + telNumber.slice(3);
    return `${countryCode} (${cityCode}) ${telNumber}`.trim();
  }
}
export interface IUserInfo {
  user: User;
}

import { Directive, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import { ChartComponent } from '../chart.component';

@Directive()
export abstract class BaseLegend {
  chart$ = new BehaviorSubject<Chart<any> | null>(null);

  @Input()
  set chart(val: Chart<any> | null) {
    this.chart$.next(val);
  }
  get chart(): Chart<any> | null {
    return this.chart$.value;
  }

  @Input()
  set legendFor(component: ChartComponent<any>) {
    component.setLegend(this);
  }
}

import { Environment } from './environment.model';

export const environment = new Environment({
  production: true,
  clearViewApi: 'https://clearview-dev.clearfreight.com/api/api/',
  clearViewUrl: 'https://clearview-dev.clearfreight.com/',
  home: 'https://cleardata-dev.clearfreight.com/',
  issuerUri: 'https://sso-dev.clearfreight.com',
  clientId: 'analytics',
  responseType: 'code',
  scope: 'IdentityServerApi profile offline_access',
});

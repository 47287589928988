import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrollObserver]',
  exportAs: 'appScrollObserver',
})
export class ScrollObserverDirective {
  public scrollLeft: number = 0;
  @Output() horizontalScroll: EventEmitter<number> = new EventEmitter<number>();
  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  public onScroll(event) {
    const scrollLeft = event.target.scrollLeft;
    this.scrollLeft = scrollLeft;
    this.horizontalScroll.emit(this.scrollLeft);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

const TABLE_FILTER_KEY = 'toggledFilter';

@Component({
  selector: 'app-common-table-paginator',
  styleUrls: ['./common-table-paginator.components.scss'],
  template: `
    <div class="table-paginator-content" *ngIf="dataLength && !hidePaginator">
      <div class="table-page-size-text">Items per page:</div>
      <div class="table-page-size">
        <select #pageSizeSelect [value]="pageSize" (change)="handlePageSizeChange(pageSizeSelect.value)">
          <option *ngFor="let size of pageSizeOptions" [value]="size">
            {{ size }}
          </option>
        </select>
      </div>
      <div class="table-total">{{ startNum }} - {{ endNum }} of {{ dataLength }}</div>
      <div class="table-paginator">
        <nz-pagination
          [nzPageIndex]="pageIndex"
          [nzPageSize]="pageSize"
          [nzTotal]="dataLength"
          (nzPageIndexChange)="paginate($event)"
        ></nz-pagination>
      </div>
      <div class="table-go-to-page">
        Go to page
        <input class="go-to-page-input" [(ngModel)]="goToPage" type="number" min="1" [max]="maxPage" />
        <button (click)="handleGoToPage()">Go</button>
      </div>
    </div>
  `,
})
export class CommonTablePaginator implements OnInit {
  @Input()
  set pageSize(value: number) {
    if (value) {
      this.maxPage = Math.ceil(this.dataLength / value);
      this._pageSize = value;
    }
  }
  get pageSize(): number {
    return this._pageSize;
  }
  private _pageSize: number = 10;

  @Input()
  set pageIndex(value: number) {
    if (value) {
      this._pageIndex = value;
    }
  }
  get pageIndex(): number {
    return this._pageIndex;
  }
  private _pageIndex: number = 1;

  @Input()
  set dataLength(value: number) {
    this.maxPage = Math.ceil(value / this.pageSize);
    this._dataLength = value;
    this.calcPageNumberRange();
  }
  get dataLength(): number {
    return this._dataLength;
  }
  private _dataLength: number = 0;

  @Input()
  set hidePaginator(value: boolean) {
    this._hidePaginator = value;
  }
  get hidePaginator(): boolean {
    return this._hidePaginator;
  }
  private _hidePaginator: boolean = true;

  @Input()
  set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = value;
  }
  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }
  private _pageSizeOptions: number[] = [10, 20, 30];

  @Input() showTotalPages: number = 3;

  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('paginator', { static: true }) paginator;

  public goToPage: number;
  public maxPage: number;
  public startNum: number;
  public endNum: number;

  constructor() {}

  ngOnInit(): void {}

  paginate(event: any) {
    this.pageIndex = event;
    const page = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.calcPageNumberRange();
    this.pageChanged.emit(page);
  }

  public handlePageSizeChange(value) {
    this.pageSize = value;
    this.goToPage = 1;
    this.pageIndex = 1;
    const page = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.calcPageNumberRange();
    this.pageChanged.emit(page);
  }

  public handleGoToPage() {
    if (this.goToPage > this.maxPage) {
      this.goToPage = this.maxPage;
    }
    this.pageIndex = this.goToPage;
    const page = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.calcPageNumberRange();
    this.pageChanged.emit(page);
  }

  public calcPageNumberRange() {
    this.startNum = (this.pageIndex - 1) * this.pageSize + 1;
    this.endNum = this.pageIndex * this.pageSize;
    if (this.endNum > this.dataLength) {
      this.endNum = this.dataLength;
    }
  }
}

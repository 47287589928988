import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RequestInterceptor } from '@modules/auth/helpers/request.interceptor';
import { ColorService } from '@modules/auth/services/color.service';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      easing: 'ease-in',
      easeTime: 500,
      tapToDismiss: true,
      closeButton: true,
    }),
  ],
  providers: [ColorService, { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }],
})
export class CoreModule {}

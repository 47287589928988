<div class="dialog-layout">
  <div class="d-flex justify-content-between header">
    <div class="header-title">
      {{ title ? title : '' }}
      <span class="header-description">{{ description }}</span>
    </div>
    <div class="d-flex justify-content-end">
      <button class="close-button" (click)="closeDialog.emit($event)" mat-mini-fab [disabled]="loading">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content #dialogContent>
    <ng-content></ng-content>
  </mat-dialog-content>
  <div class="footer" *ngIf="showFooter">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

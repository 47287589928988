import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@models/organization.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private httpClient: HttpClient) {}

  public getOrganizations(): Observable<Organization[]> {
    return this.httpClient.get<Organization[]>(`${environment.clearViewApi}analysis/organiztions`);
  }
}

import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ITableColumnConfig } from '../components/common-table/table-configurations';

@Pipe({
  name: 'tableCell',
})
export class TableCellPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) {}

  transform(value: any, column: ITableColumnConfig) {
    if (column.type === 'date') {
      return this.datePipe.transform(value[column.key], 'shortDate');
    }
    if (column.type === 'location') {
      return value[column.key]?.code;
    }
    if (column.type === 'orgName') {
      return value[column.key]?.name;
    }
    if (column.type === 'numberWithUnit') {
      if (value[column.key] !== undefined && value[column.key] !== null) {
        if (value[column.unitKey] !== undefined && value[column.unitKey] !== null) {
          return value[column.key] + value[column.unitKey];
        } else {
          return value[column.key];
        }
      } else {
        return '';
      }
    }
    if (column.type === 'amount') {
      if (value[column.key] !== undefined && value[column.key] !== null) {
        if (value[column.unitKey] !== undefined && value[column.unitKey] !== null) {
          return this.currencyPipe.transform(value[column.key], value[column.unitKey]);
        } else {
          return this.currencyPipe.transform(value[column.key]);
        }
      } else {
        return '';
      }
    }
    return value[column.key];
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '@models/menu.model';
import { AuthService } from '@modules/auth/services/auth.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ImpersonateUserComponent } from 'src/app/modules/auth/components/impersonate-user/impersonate-user.component';
import { UserService } from 'src/app/modules/auth/services/user.service';
import { environment } from 'src/environments/environment';

export interface INavLink {
  url: string;
  name: string;
  svgIcon: string;
  userMode: number;
}

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('fadeInOutTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppLayoutComponent implements OnInit {
  public sidebarExpanded = false;
  public currentParentMenu = 'Dashboard';
  public showChildrenMenu = 'Dashboard';
  public currentChildMenu = '';
  public initialRoute = true;
  public initialRouteAddress = '';

  public searchValue = '';

  public navLinks: MenuItem[] = [
    {
      routeAddress: 'app/dashboard',
      labelName: 'Dashboard',
      imageName: 'shapes-and-symbols',
      showArrow: false,
      enabled: true,
    },
  ];

  public webServiceRoutesDefault: MenuItem[] = [
    {
      imageName: 'bar-chart',
      routeAddress: '',
      labelName: 'Other Web Service',
      showArrow: false,
      externalLink: true,
      enabled: true,
      childrenMenu: [
        {
          routeAddress: environment.clearViewUrl,
          labelName: 'ClearView',
          externalLink: true,
          enabled: true,
        },
      ],
    },
  ];

  public userNavigationLinks: MenuItem[] = [];
  public webServiceRoutes: MenuItem[] = [];

  searchQuery = '';
  searchQuery$ = new BehaviorSubject<string>('');

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public userService: UserService,
    public matDialog: MatDialog,
    private router: Router,
    public authService: AuthService
  ) {
    this.matIconRegistry.addSvgIcon(
      'shapes-and-symbols',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/shapes-and-symbols.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bar-chart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/bar-chart.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'right-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'left-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'down-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/keyboard_arrow_down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delivery-search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/delivery-search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'storage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/storage.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'account_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/account_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu-book',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/menu_book.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/clipboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar_view_week',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/calendar_view_week.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stream',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/stream.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'people',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/people.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'import',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/import.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rejection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/rejection.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'question-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/question_mark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'configuration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/configurations.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-excel-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-lines',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-lines-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-pdf-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-word',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-word-solid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-zipper',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/file-zipper-solid.svg')
    );
    this.matIconRegistry.addSvgIcon('route', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/route.svg'));
    this.matIconRegistry.addSvgIcon(
      'shipments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/shipments.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'people-setting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/people-setting.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'track-package',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/track-package.svg')
    );

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      /** Set initial route */
      if (this.initialRoute) {
        this.initialRouteAddress = event.urlAfterRedirects.slice(1);
      }
      /* Set menu when change org/network */
      if (!this.initialRoute) {
        this.setCurrentMenu(event.urlAfterRedirects.slice(1));
      }
    });
  }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      if (Object.keys(user).length) {
        this.configureNavigationForUser();
      }
    });
  }

  public filteredOrgs = combineLatest([this.searchQuery$, this.userService.orgList$]).pipe(
    map(([q, os]) => {
      const lq = q.toLowerCase();
      const orgAlpha = [...os].sort((a, b) => (a.cfOrgName! > b.cfOrgName! ? 1 : -1));
      return orgAlpha.filter(o => !q || (o.cfOrgName && o.cfOrgName.toLowerCase().includes(lq)));
    })
  );

  public impersonateUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '500px';
    dialogConfig.width = 'auto';
    this.matDialog.open(ImpersonateUserComponent, dialogConfig).afterClosed().subscribe();
  }

  private configureNavigationForUser(): void {
    this.userNavigationLinks = [...this.navLinks.slice(0)];
    this.webServiceRoutes = [...this.webServiceRoutesDefault.slice(0)];
    if (this.initialRoute) {
      this.initialRoute = false;
      this.setCurrentMenu(this.initialRouteAddress);
    }
  }

  public changeNavOpen(event: boolean): void {
    this.sidebarExpanded = event;
  }

  public openChildrenMenu(event: string): void {
    this.showChildrenMenu = event;
  }

  public changeParentMenu(event: string): void {
    this.currentParentMenu = event;
  }

  public changeCurrentChildMenu(event: string): void {
    this.currentChildMenu = event;
  }

  public setCurrentMenu(routerUrl: string): void {
    let enter = false;
    this.userNavigationLinks.forEach(item => {
      if (!item.childrenMenu && routerUrl.indexOf(item.routeAddress) != -1) {
        this.openChildrenMenu('');
        this.changeCurrentChildMenu('');
        this.changeParentMenu(item.labelName);
        let enter = true;
      } else if (item.childrenMenu) {
        item.childrenMenu.forEach(child => {
          if (routerUrl.indexOf(child.routeAddress) != -1) {
            this.openChildrenMenu(item.labelName);
            this.changeCurrentChildMenu(child.labelName);
            this.changeParentMenu(item.labelName);
            let enter = true;
          }
        });
      }
    });
  }
}

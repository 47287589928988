import { Component, OnInit } from '@angular/core';
import { LegendItem } from 'chart.js';
import { map } from 'rxjs/operators';
import { BaseLegend } from '../abstract-legend';

@Component({
  selector: 'app-pretty-legend',
  templateUrl: './pretty-legend.component.html',
  styleUrls: ['./pretty-legend.component.scss'],
})
export class PrettyLegendComponent extends BaseLegend implements OnInit {
  ngOnInit(): void {}

  public items$ = this.chart$.pipe(
    map(chart => {
      if (!chart) return [];
      let items: LegendItem[] = chart.options.plugins.legend.labels.generateLabels(chart);
      if (chart.options.plugins.legend.labels.filter) items = items.filter(chart.options.plugins.legend.labels.filter);
      return items;
    })
  );
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartTypeRegistry } from 'chart.js';
import { BaseLegend } from './legends/abstract-legend';

@Component({
  selector: 'app-chart',
  template: '<canvas #chartCanvas></canvas>',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent<ChartType extends keyof ChartTypeRegistry> implements OnInit, AfterViewInit {
  constructor() {}

  #data: ChartData<ChartType> = { labels: [], datasets: [] };

  #legendRef?: BaseLegend;

  @Input()
  get data(): ChartData<ChartType> {
    return this.#data;
  }
  set data(data: ChartData<ChartType>) {
    Object.assign(this.#data, data);
    this.chart && this.chart.update();
  }

  @Input()
  type!: ChartType;

  @Input()
  options: ChartOptions<ChartType> | undefined;

  @ViewChild('chartCanvas', { read: ElementRef })
  canvas!: ElementRef<HTMLCanvasElement>;

  public setLegend(legend: BaseLegend) {
    this.#legendRef = legend;
    this.hideLegend();
  }

  public chart: Chart<ChartType> | null = null;

  ngOnInit(): void {
    if (!this.data) throw new Error('No data supplied to chart!');
    if (!this.type) throw new Error('No type supplied to chart!');
  }

  ngAfterViewInit() {
    if (this.#legendRef) this.hideLegend();
    this.chart = new Chart(this.canvas.nativeElement.getContext('2d')!, {
      data: this.#data,
      type: this.type,
      options: this.options,
    });
    if (this.#legendRef) this.#legendRef.chart = this.chart;

    // if (this.type === 'line') {
    //   // Get the x-axis scale
    //   const scale = this.chart.scales.x;
    //   // Generate ticks for the scale
    //   if (scale) {
    //     const ticks = scale.getTicks();
    //     // Get the actual displayed labels
    //   }
    // }
  }

  private hideLegend() {
    if (!this.options) return;
    const opts = <ChartOptions>this.options;
    if (!opts.plugins) opts.plugins = {};
    if (!opts.plugins.legend) opts.plugins.legend = {};
    opts.plugins.legend.display = false;
  }
}

import { Component, ContentChildren, QueryList } from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

@Component({
  selector: 'app-common-table-custom-cells-wrapper',
  styles: [''],
  template: `
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  `,
})
export class CustomCellsWrapperComponent {
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  constructor() {}
}

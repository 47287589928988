import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent implements OnInit, AfterViewInit {
  @Input() public title: string = '';
  @Input() public description: string = '';
  @Input() public showFooter: boolean = false;
  @Input() public loading: boolean = false;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dialogContent') dialogContent: ElementRef;

  constructor(public dialogService: DialogService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.dialogContent) {
      this.dialogService.dialogHeight = this.dialogContent.nativeElement.offsetHeight;
    }
  }
}

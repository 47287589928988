<table style="min-width: 350px" fxFlex="1 1 auto" *ngIf="chart$ | async as loadedChart">
  <tr>
    <th></th>
    <th style="text-align: center">{{ categoryName }}</th>
    <ng-container *ngFor="let dataset of currentChart.data.datasets">
      <th *ngIf="dataset.label !== 'Other' && dataset.label !== 'Currency'" style="text-align: center">
        {{ dataset.label }}
      </th>
    </ng-container>
  </tr>
  <ng-container *ngFor="let label of currentLabels || []; let i = index">
    <tr *ngIf="label.text !== 'Other'; else otherBreakdown">
      <td><span class="box" [style.backgroundColor]="label.fillStyle"></span></td>
      <td style="text-align: left">{{ label.text }}</td>
      <ng-container *ngFor="let dataset of loadedChart.data.datasets">
        <td *ngIf="dataset.label !== 'Other' && dataset.label !== 'Currency'">
          {{
            dataFormatter ? dataFormatter(dataset.data[i], dataset.label, label.text, currentCurrency) : dataset.data[i]
          }}
        </td>
      </ng-container>
    </tr>
    <ng-template #otherBreakdown>
      <tr *ngFor="let otherData of otherDataset!.data" class="other-item-row">
        <td>
          <span
            class="box"
            [style.background]="
              'repeating-linear-gradient(45deg, ' +
              label.fillStyle +
              ', ' +
              label.fillStyle +
              ' 4px, transparent 4px, transparent 5px)'
            "
          ></span>
        </td>
        <td style="text-align: left">{{ otherData[0] }}</td>
        <td *ngFor="let otherDataPoint of otherData[1]">{{ otherDataPoint }}</td>
      </tr>
    </ng-template>
  </ng-container>
</table>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { UnauthorizedPage } from '@pages/unauthorized/unauthorized-page.component';
// import { InviteGuard } from './core/guards/invite.guard';
import { AppLayoutComponent } from './layouts/app/app-layout.component';
import { AuthGuard } from './modules/auth/helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
    ],
  },
  //   {
  //     path: '',
  //     children: [
  //       {
  //         path: 'create-password/:inviteToken',
  //         canActivate: [InviteGuard],
  //         component: UserPasswordComponent,
  //       },
  //       {
  //         path: 'forbidden',
  //         component: ForbiddenPage,
  //       },
  //       {
  //         path: 'unauthorized',
  //         component: UnauthorizedPage,
  //       },
  //     ],
  //   },
  //   {
  //     path: '**',
  //     component: NotFoundPage,
  //   },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

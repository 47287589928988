export class Environment {
  public appVersion!: string;
  public production!: boolean;
  public clearViewApi!: string;
  public clearViewUrl!: string;
  public home!: string;
  public analytics!: {
    account: string;
    key: string;
  };
  public issuerUri!: string;
  public clientId!: string;
  public responseType!: string;
  public scope!: string;

  public get majorVersion(): string {
    // 1.2.3-env => "1"
    return this.appVersion?.substring(0, this.appVersion?.indexOf('.')) ?? '';
  }

  public get minorVersion(): string {
    // 1.2.3-env => "2"
    return this.appVersion?.substring(this.appVersion?.indexOf('.') + 1, this.appVersion?.lastIndexOf('.')) ?? '';
  }

  public get patchVersion(): string {
    // 1.2.3-env => "3"
    return this.appVersion?.substring(this.appVersion?.lastIndexOf('.') + 1, this.appVersion?.indexOf('-')) ?? '';
  }

  constructor(init?: Partial<Environment>) {
    Object.assign(this, init);
  }
}
